<template>
  <div>
    <van-nav-bar
      :title="msg"
      :left-arrow="left=='false'?false:true"
      @click-left="onClickLeft"
      @click-right="onClickRight"
    />
  </div>
</template>
<script>
export default {
  props: {
    msg: String,
    left:String
  },
  methods:{
      onClickLeft(){
          this.$router.go(-1)
      },
      onClickRight(){
          
      }
  }
};
</script>