<!--
 * @Author: your name
 * @Date: 2021-01-08 17:09:11
 * @LastEditTime: 2021-04-01 11:59:17
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \two\src\components\share\index.vue
-->
<template>
  <div>
    <van-share-sheet
      v-model="showShare"
      title="立即分享给好友"
      :options="options"
      @select="onSelect"
      @cancel="clickcancel"
    />
    <div v-show="isshare == true || isshare2 == true" class="msk"></div>
    <div v-show="isshare == true" class="tipclass">
      <p>点击右上角“...”生成链接转发</p>
      <van-icon @click="okmeth" size=".3rem" class="vanclass" name="cross" />
    </div>
    <div v-show="isshare2 == true" class="tipclass2">
      <p>点击右上角“<van-icon name="share" />”生成链接转发</p>
      <van-icon @click="okmeth" size=".3rem" class="vanclass" name="cross" />
    </div>
    <!-- <div v-show="isshare4 == true" class="tipclass3">
      <p>分享成功后，点击左上方 “<van-icon name="arrow-left" />” <br>返回到上一页</p>
      <button @click="okmeth2" id="okclass">确认</button>
    </div> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      isshare: false,
      isshare2: false,
      showShare: false,
      options: [
        [
          { name: "微信", icon: "wechat" },
          { name: "QQ", icon: "qq" },
        ],
        // [
        //   { name: "复制链接", icon: "link" },
        //   { name: "分享海报", icon: "poster" },
        //   { name: "二维码", icon: "qrcode" },
        // ],
      ],
    };
  },
  mounted() {},
  methods: {
    /**
     * 点击取消分享
     */
    clickcancel() {
      // this.$router.go(-1);
    },
    clickshare() {
      this.showShare = true;
    },
    onSelect(e) {
      if (e.icon == "wechat" || e.icon == "qq") {
        this.txmeth();
        this.showShare = false;
      }
    },
    okmeth() {
      let wx = navigator.userAgent.toLowerCase();
      if (wx.match(/MicroMessenger/i) == "micromessenger") {
        this.isshare = false;
        // this.$router.go(-1);
        return;
      } else {
        this.isshare2 = false;
        // this.$router.go(-1);
      }
    },
    okmeth2() {},
    txmeth() {
      let wx = navigator.userAgent.toLowerCase();
      if (wx.match(/MicroMessenger/i) == "micromessenger") {
        this.isshare = true;
        return;
      } else {
        this.isshare2 = true;
      }
      this.$parent.childsharemeth();
    },
  },
};
</script>
<style scoped>
.vanclass {
  position: absolute;
  top: 0;
  right: 0;
}
#okclass {
  margin-left: 50%;
  transform: translateX(-50%);
  border: none;
  padding: 0.1rem 0.3rem;
  border-radius: 0.1rem;
  background-color: antiquewhite;
  margin-top: 0.2rem;
}
.tipclass {
  position: fixed;
  top: 0.2rem;
  right: 0;
  z-index: 1000;
  background: #ffffff;
  padding: 0.3rem 0.2rem;
  border: 1px solid rgb(201, 200, 200);
  border-radius: 0.2rem;
  font-size: 0.2rem;
}
.tipclass3 {
  position: fixed;
  top: 1rem;
  left: 0;
  z-index: 1000;
  background: #ffffff;
  padding: 0.2rem;
  border-radius: 0.2rem;
  font-size: 0.2rem;
}
.tipclass2 {
  position: fixed;
  bottom: 0.2rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  background: #ffffff;
  padding: 0.3rem 0.2rem;
  border: 1px solid rgb(201, 200, 200);
  border-radius: 0.2rem;
  font-size: 0.2rem;
}
.aaa {
  margin-top: 0.3rem;
}
.all {
  padding-bottom: 1rem;
}
/*
  遮罩层
*/
.msk {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}
</style>
